export function setFont(c) {
  const body = document.querySelector('body');

  for (let k of body.classList.values()) {
    if (k.startsWith('font-')) {
      body.classList.remove(k);
    }
  }

  window.localStorage.setItem('font', c || 'system');
  if (c) body.classList.add('font-' + c);
}

export function updateFont() {
  if (window.localStorage.getItem('font')) {
    const font = window.localStorage.getItem('font');
    document.querySelectorAll('.font-select input').forEach(v => v.checked = false);
    // queryselectorall hack to prevent an undefined err
    document.querySelectorAll(`.font-select #${font}`).forEach(v => v.checked = true);
    if (font === 'system') {
      setFont();
    } else {
      setFont(font);
    }
  }
}

document.addEventListener('DOMContentLoaded', () => {
  updateFont();
});